<template>
  <v-sheet color="white" elevation="2" class="center ml-10 mb-10">
    <p>
      <span :class="getItem.cor + ' numero'">{{ total }}</span
      ><br />
      <span :class="getItem.cor + ' texto'">{{ getItem.label }}</span>
    </p>
    <div :class="getItem.fundo + ' cantoDireito'">
      <v-icon color="white">{{ getItem.icon }}</v-icon>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    situacao: {
      type: String,
      require: true
    },
    total: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    lista: [
      {
        situacao: "APROVADO",
        cor: "texto-green",
        fundo: "fundo-green",
        label: "Publicado",
        icon: "mdi-check"
      },
      {
        situacao: "EM_ELABORACAO",
        cor: "texto-blue",
        fundo: "fundo-blue",
        label: "Em elaboração",
        icon: "mdi-pencil"
      },
      {
        situacao: "AGUARDANDO_PUBLICAÇÃO",
        cor: "texto-orange",
        fundo: "fundo-orange",
        label: "Aguardando publicação",
        icon: "mdi-timer-sand"
      },
/*       {
        situacao: "AGUARDANDO_APROVACAO",
        cor: "texto-orange",
        fundo: "fundo-orange",
        label: "Aguardando publicação",
        icon: "mdi-timer-sand"
      }, */
/*       {
        situacao: "URGENTE",
        cor: "texto-red",
        fundo: "fundo-red",
        label: "Aprovação urgente",
        icon: "mdi-alert"
      }, */
      {
        situacao: "URGENTE",
        cor: "texto-red",
        fundo: "fundo-red",
        label: "Aguardando publicação",
        icon: "mdi-alert"
      },
      {
        situacao: "IMAGEM",
        cor: "texto-imagem",
        fundo: "fundo-imagem",
        label: "Imagem",
        icon: "mdi-image"
      },
      {
        situacao: "VIDEO",
        cor: "texto-video",
        fundo: "fundo-video",
        label: "Vídeo",
        icon: "mdi-video"
      }
    ]
  }),
  computed: {
    getItem() {
      let item = this.lista.filter(item => item.situacao === this.situacao);
      if (item.length == 0) {
        return {
          situacao: this.situacao,
          cor: "texto-blue",
          fundo: "fundo-blue",
          label: this.situacao,
          icon: ""
        };
      }
      return item[0];
    }
  }
};
</script>
<style>
.numero {
  font-size: 36px;
  font-weight: bold;
}
.texto {
  font-weight: 500;
}
.texto-blue {
  color: #1976d2;
}
.fundo-blue {
  background: #1976d2;
}
.texto-orange {
  color: #f57c00;
}
.fundo-orange {
  background: #f57c00;
}
.texto-red {
  color: #d32f2f;
}
.fundo-red {
  background: #d32f2f;
}
.texto-green {
  color: #388e3c;
}
.fundo-green {
  background: #388e3c;
}
.texto-black {
  color: #000000;
}
.fundo-black {
  background: #000000;
}
.texto-imagem {
  color: #5d4037;
}
.fundo-imagem {
  background: #5d4037;
}
.texto-video {
  color: #455a64;
}
.fundo-video {
  background: #455a64;
}

.cantoDireito {
  margin: 0;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 45px;
  width: 45px;
  border-bottom-left-radius: 40px;
}
.cantoDireito i {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
}
</style>
