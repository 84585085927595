<template>
  <v-container pa-5 fluid>
    <template v-if="loading">
      <v-row no-gutters style="height: 90vh; width: 90vw" class="text-center">
        <v-col align-self="center">
          <v-progress-circular indeterminate color="primary" :size="70" />
        </v-col>
      </v-row>
    </template>

    <h2 class="mb-5">Dashboard</h2>

    <v-row>
      <v-col md="7" sm="12">
        <div class="container-banner">
          <banner
            :loading="loadingBanner"
            :bannerUrl="configuracaoGeral.bannerUrl"
          />
        </div>
      </v-col>
      <v-col md="5" sm="12">
        <v-card
          class="mx-auto"
          elevation="1"
          max-width="100vw"
          :height="isMobileView ? '100%' : 200"
        >
          <v-card-text>
            <v-row class="ml-5">
              <v-col md="6" sm="12" cols="12">
                <div>
                  <v-row align="center">
                    <v-btn fab small elevation="1" color="blue lighten-5">
                      <v-icon color="primary"> mdi-calendar </v-icon>
                    </v-btn>
                    <v-col>
                      <span class="font-weight-black">{{
                        dados.totalizador.totalPost
                      }}</span
                      ><br />
                      <span class="caption font-weight-bold">Posts</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <div>
                  <v-row align="center">
                    <v-btn fab small elevation="1" color="blue lighten-5">
                      <v-icon color="primary"> mdi-account-multiple </v-icon>
                    </v-btn>
                    <v-col>
                      <span class="font-weight-black">{{
                        dados.totalizador.totalVisitantes
                      }}</span
                      ><br />
                      <span class="caption font-weight-bold">Visitantes</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <div>
                  <v-row align="center">
                    <v-btn fab small elevation="1" color="blue lighten-5">
                      <v-icon color="primary"> mdi-star </v-icon>
                    </v-btn>
                    <v-col>
                      <span class="font-weight-bold">{{
                        dados.totalizador.totalAssinantes
                      }}</span
                      ><br />
                      <span class="caption font-weight-bold">Assinantes</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <div>
                  <v-row align="center">
                    <v-btn fab small elevation="1" color="blue lighten-5">
                      <v-icon color="primary"> mdi-thumb-up </v-icon>
                    </v-btn>
                    <v-col>
                      <span class="font-weight-bold">{{
                        dados.totalizador.totalCurtidas
                      }}</span
                      ><br />
                      <span class="caption font-weight-bold">Curtidas</span>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col align="right" md="12" cols="12">
                <label style="cursor: pointer" @click="refresh">
                  <span class="text-atualizar"
                    >Atualizado em {{ dados.dataHoraAtualizacao }}</span
                  >
                  <v-icon small color="primary"> mdi-refresh </v-icon>
                </label>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row
          v-if="instalacao.linkRelatorio"
          justify="center"
          class="mt-4 ml-3 mb-0 pb-0"
        >
          <a :href="instalacao.linkRelatorio" target="_blank"
            >Painel de Acompanhamento</a
          >
        </v-row>
      </v-col>
    </v-row>

    <template>
      <v-card class="pa-3 containerTab d-block">
        <v-tabs active-class="tabselected">
          <v-tab v-if="hasFinanceiro" class="text-capitalize">
            Financeiro
          </v-tab>

          <v-tab v-if="!isMobileView" class="text-capitalize">
            Publicações
          </v-tab>

          <!-- Publicacoes -->
          <v-tab-item v-if="hasFinanceiro" class="borderTopRed">
            <div
              :class="!isMobile ? 'd-flex justify-space-between flex-wrap' : ``"
            >
              <div class="d-flex align-center">
                <v-btn fab small elevation="1" color="blue lighten-5">
                  <v-icon color="primary"> mdi-cash </v-icon>
                </v-btn>
                <v-col>
                  <span
                    >R$
                    <span class="font-weight-bold">{{
                      asMoney(valores.valorHoje)
                    }}</span></span
                  ><br />
                  <span class="caption">Minhas Vendas Hoje</span>
                </v-col>
              </div>
              <div class="d-flex align-center">
                <v-btn fab small elevation="1" color="blue lighten-5">
                  <v-icon color="primary"> mdi-cash-multiple </v-icon>
                </v-btn>
                <v-col>
                  <span
                    >R$
                    <span class="font-weight-bold">{{
                      asMoney(valores.valorMes)
                    }}</span></span
                  ><br />
                  <span class="caption">Minhas Vendas do Mês</span>
                </v-col>
              </div>
              <div class="d-flex align-center">
                <v-btn fab small elevation="1" color="blue lighten-5">
                  <v-icon color="primary"> mdi-cash-check </v-icon>
                </v-btn>
                <v-col>
                  <span
                    >R$
                    <span class="font-weight-bold">{{
                      asMoney(valores.saldoLiberado)
                    }}</span></span
                  ><br />
                  <span class="caption">Saldo Liberado</span>
                </v-col>
              </div>
              <div class="d-flex align-center">
                <v-btn fab small elevation="1" color="blue lighten-5">
                  <v-icon color="primary"> mdi-cash-clock </v-icon>
                </v-btn>
                <v-col>
                  <span
                    >R$
                    <span class="font-weight-bold">{{
                      asMoney(valores.saldoLiberar)
                    }}</span></span
                  ><br />
                  <span class="caption">Saldo a Liberar</span>
                </v-col>
              </div>
              <div class="d-flex align-center">
                <v-btn fab small elevation="1" color="blue lighten-5">
                  <v-icon color="primary"> mdi-cash-100 </v-icon>
                </v-btn>
                <v-col>
                  <span
                    >R$
                    <span class="font-weight-bold">{{
                      asMoney(valores.saldoTotal)
                    }}</span></span
                  ><br />
                  <span class="caption">Saldo Total</span>
                </v-col>
              </div>
            </div>
            <div align="right" class="mt-2 mb-2">
              <v-btn color="primary" @click="handleExtrato"> Extrato</v-btn>
            </div>
            <div v-if="hasFinanceiro && showExtrato" class="borderTopRed">
              <div class="d-flex align-baseline">
                <v-select
                  v-model="datas"
                  :items="extratoDates"
                  item-text="text"
                  item-value="value"
                ></v-select>
                <v-btn
                  outlined
                  color="primary"
                  class="ml-4"
                  @click="handleExtratoPesquisa"
                >
                  Pesquisar</v-btn
                >
              </div>
              <v-card v-if="extratoData && !loadingExtrato">
                <div class="d-flex justify-space-between mb-2">
                  <span> Saldo Anterior: </span>
                  <span class="font-weight-bold"
                    >R$
                    {{ asMoney(extratoData.saldoAnterior.saldoTotal) }}</span
                  >
                </div>
                <div v-for="(item, index) in extratoData.extrato" :key="index">
                  <div class="background-date">
                    <span class="text-subtitle-1">
                      {{ item.dataMovimentacao | filtrarExtenso }}</span
                    >
                  </div>
                  <div class="d-flex justify-space-between">
                    <span class="text-subtitle-2">
                      {{ item.historico }} - {{ item.tipoHistorico }}</span
                    >
                    <span
                      v-if="item.classificacao == 'C'"
                      class="text-subtitle-2 green--text text--lighten-1"
                    >
                      + R$ {{ asMoney(item.valorCliente) }}</span
                    >
                    <span
                      v-if="item.classificacao == 'D'"
                      class="text-subtitle-2 red--text text--lighten-1"
                    >
                      - R$ {{ asMoney(item.valorCliente) }}</span
                    >
                  </div>
                </div>
                <v-divider class="mt-2" />
                <div class="text-subtitle-2 mt-2">
                  <div class="d-flex justify-space-between flex-wrap">
                    <span class="font-weight-bold"> Valor do Mês: </span>
                    <span class="font-weight-bold"
                      >R$ {{ asMoney(extratoData.saldoAtual.valorMes) }}</span
                    >
                  </div>
                  <div class="d-flex justify-space-between flex-wrap">
                    <span class="font-weight-bold"> Saldo Liberado: </span>
                    <span class="font-weight-bold"
                      >R$ {{ asMoney(extratoData.saldoAtual.saldoLiberado) }}
                    </span>
                  </div>
                  <div class="d-flex justify-space-between flex-wrap">
                    <span class="font-weight-bold"> Saldo a Liberar: </span>
                    <span class="font-weight-bold"
                      >R$
                      {{ asMoney(extratoData.saldoAtual.saldoLiberar) }}</span
                    >
                  </div>
                  <div class="d-flex justify-space-between flex-wrap">
                    <span class="font-weight-bold"> Saldo Total: </span>
                    <span class="font-weight-bold"
                      >R$ {{ asMoney(extratoData.saldoAtual.saldoTotal) }}</span
                    >
                  </div>
                </div>
              </v-card>
              <template v-if="loadingExtrato">
                <v-row
                  no-gutters
                  style="height: 10vh; width: 90vw"
                  class="text-center"
                >
                  <v-col align-self="center">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="70"
                    />
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-tab-item>

          <v-tab-item v-if="!isMobileView" class="borderTopRed">
            <v-card>
              <v-tabs :vertical="isMobileView ? false : true" :slider-size="5">
                <v-tab class="text-capitalize tabVerticalAlignTitle">
                  Em andamento
                </v-tab>
                <v-tab class="text-capitalize tabVerticalAlignTitle">
                  Concluídas - Categoria
                </v-tab>
                <v-tab class="text-capitalize tabVerticalAlignTitle">
                  Concluídas - Cliente
                </v-tab>
                <v-tab class="text-capitalize tabVerticalAlignTitle">
                  Mídias
                </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col
                          md="3"
                          sm="6"
                          xs="12"
                          cols="12"
                          v-for="(item, i) in dados.andamento"
                          :key="i"
                        >
                          <box-item-dash
                            :situacao="item.tipo"
                            :total="item.total"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />
                    <div align="right">
                      <span class="font-weight-bold">Total: </span
                      >{{ dados.totalAndamento }}
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col
                          md="3"
                          sm="6"
                          xs="12"
                          cols="12"
                          v-for="(item, i) in dados.concluidasCategoria"
                          :key="i"
                        >
                          <box-item-dash
                            :situacao="item.tipo"
                            :total="item.total"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-divider />
                    <div align="right">
                      <span class="font-weight-bold">Total: </span
                      >{{ dados.totalConcluidasCategoria }}
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col
                          md="3"
                          sm="12"
                          xs="12"
                          cols="12"
                          v-for="(item, i) in dados.concluidasCliente"
                          :key="i"
                        >
                          <box-item-dash
                            :situacao="item.tipo"
                            :total="item.total"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <div align="right">
                        <span class="font-weight-bold">Total: </span
                        >{{ dados.totalConcluidasCliente }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row no-gutters>
                        <v-col
                          md="3"
                          sm="12"
                          xs="12"
                          cols="12"
                          v-for="(item, i) in dados.midias"
                          :key="i"
                        >
                          <box-item-dash
                            :situacao="item.tipo"
                            :total="item.total"
                          />
                        </v-col>
                      </v-row>
                      <v-divider />
                      <div align="right">
                        <span class="font-weight-bold">Total: </span
                        >{{ dados.totalMidias }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import Banner from "../../components/banner.vue";
import boxItemDash from "../../components/box-item-dash.vue";
import {
  DashboardService,
  ConfiguracaoGeralService,
  FinanceiroService,
} from "../../services";
import { Role } from "@/constants";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { boxItemDash, Banner },
  data: () => ({
    loading: false,
    loadingBanner: false,
    valores: {},
    extratoData: null,
    datas: [],
    loadingExtrato: false,
    showExtrato: false,
    extratoDates: [],
    formData: null,
    file: {},
    dados: {
      totalizador: {},
    },
    configuracaoGeral: {},
    isMobile: eval(process.env.VUE_APP_IS_MOBILE),
    instalacao: {},
  }),
  filters: {
    filtraDH_withTime(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY HH:mm");
      } else {
        return "Inválido";
      }
    },
    filtrarExtenso(val) {
      if (val) {
        return moment(val).format("LLLL");
      } else {
        return "Inválido";
      }
    },
  },

  beforeMount() {
    this.loadingBanner = true;
    ConfiguracaoGeralService.getConfiguracaoGeral()
      .then((response) => {
        this.configuracaoGeral = response.data;
      })
      .finally(() => {
        this.loadingBanner = false;
      });
    this.getCalendario();
    this.refresh();
  },
  computed: {
    isMobileView() {
      return window.innerWidth <= 750;
    },
    ...mapGetters({
      hasFinanceiro: "instalacao/getFinanceiros",
    }),
  },
  created() {
    if (this.isMobile) {
      this.$router.push("/check-in");
    }
    this.getDadosFinanceiros();
    if (
      this.$store.getters["user/getUser"].roleId !== Role.ROOT &&
      this.$store.getters["user/getUser"].roleId !== Role.ADMIN &&
      this.$store.getters["user/getUser"].roleId !== Role.USER
    ) {
      this.$router.push("/check-in");
    }
  },

  methods: {
    filtrarMes(val) {
      if (val) {
        if (val == 1) return "Janeiro";
        if (val == 2) return "Fevereiro";
        if (val == 3) return "Março";
        if (val == 4) return "Abril";
        if (val == 5) return "Maio";
        if (val == 6) return "Junho";
        if (val == 7) return "Julho";
        if (val == 8) return "Agosto";
        if (val == 9) return "Setembro";
        if (val == 10) return "Outubro";
        if (val == 11) return "Novembro";
        if (val == 12) return "Dezembro";
      } else {
        return "Inválido";
      }
    },

    handleExtrato() {
      this.showExtrato = true;
      this.datas.push(moment().month() + 1);
      this.datas.push(moment().year());
      this.handleExtratoPesquisa();
    },

    handleExtratoPesquisa() {
      if (this.datas == []) return this.$root.showErro("Defina uma data");
      this.loadingExtrato = true;
      FinanceiroService.getExtrato(this.datas[0], this.datas[1])
        .then((response) => {
          this.extratoData = response.data;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error);
          }
        })
        .finally(() => {
          this.loadingExtrato = false;
        });
    },

    getCalendario() {
      FinanceiroService.getCalendario().then((response) => {
        this.extratoDates = response.data.map((d) => {
          return {
            text: this.filtrarMes(d.mes) + `,` + d.ano,
            value: [d.mes, d.ano],
          };
        });
      });
    },

    refresh() {
      this.loading = true;
      DashboardService.getDashboard()
        .then((response) => {
          this.dados = response.data;
        })
        .catch((error) => {
          if (error.status != 401) {
            this.$root.showErro(error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadBanner(event) {
      if (event && event.target && event.target.files.length > 0) {
        this.file = event.target.files[0];
        this.formData = new FormData();
        this.formData.append("file", this.file);
        this.formData.append("name", this.file.name);
        this.loadingBanner = true;
        ConfiguracaoGeralService.uploadBanner(this.formData)
          .then((response) => {
            this.configuracaoGeral.bannerUrl = response.data;
          })
          .catch(() =>
            this.$root.showErro(
              "Ocorreu um erro ao enviar a imagem, tente novamente mais tarde."
            )
          )
          .finally(() => {
            this.loadingBanner = false;
          });
      }
    },

    getDadosFinanceiros() {
      FinanceiroService.getMovimentacaoCliente().then((response) => {
        this.valores = response.data;
      });
    },
  },
};
</script>

<style>
.box-atualizar {
  position: absolute;
  right: 0px;
  bottom: -25px;
  padding: 5px;
  border-top: none;
}

.text-atualizar {
  font-size: 11px;
  font-weight: 700;
  margin-right: 5px;
}

.cardTab {
  border-radius: 3px 3px 0px 3px !important;
  box-shadow: 0 2rem 2rem #00000080;
}

.tabs {
  text-align: right;
}
.tab {
  position: absolute;
  cursor: pointer;
  padding: 10px 20px;
  background: var(--v-white-base);
  display: inline-block;
  color: var(--v-black-base);
  border-radius: 0px 0px 3px 3px;
  border-top-color: var(--v-white-base);
  z-index: 2;
  right: 20px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 0px 0px rgb(0 0 0 / 12%) !important;
}

.containerTab {
  margin-top: 60px;
}

.tabselected {
  background: var(--v-primary-base);
  color: var(--v-white-base) !important;
  border-radius: 5px 5px 0px 0px;
}

.tabVerticalAlignTitle {
  justify-content: start;
}

.center {
  height: 150px;
  width: 150px;
  position: relative;
  text-align: center;
}
.center p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
}
.borderTopRed {
  border-top: 2px solid var(--v-primary-base) !important;
  border-radius: 0px !important;
}
.container-banner {
  position: relative;
}
.btn-edit-banner {
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 5px;
  background-color: var(--v-white-base);
  border-radius: 30px;
}
.btn-edit-banner:hover {
  color: var(--v-primary-base);
  background-color: var(--v-ice-base);
}
.v-slide-group__prev {
  display: none !important;
}
.border-box {
  border: 0.5px solid rgba(16, 16, 16, 0.25);
  border-radius: 5px;
}
.background-date {
  background-color: #ddd;
}
</style>
