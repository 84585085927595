<template>
  <div class="parent">
    <v-progress-circular
      class="loading-center"
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <div v-else>
      <v-img
        v-if="bannerUrl"
        :src="bannerUrl"
        :max-height="maxHeight"
        contain
      ></v-img>
      <!-- <v-img
        v-else-if="isInfluencers() && !bannerUrl"
        src="@/assets/bet_cover.png"
        :max-height="maxHeight"
        contain
      ></v-img>
      <v-img
        v-else-if="!isInfluencers() && !bannerUrl"
        src="@/assets/milgrau_cover.png"
        :max-height="maxHeight"
        contain
      ></v-img> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerUrl: {
      type: String,
      required: false,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: String,
      default: "200px"
    }
  },


};
</script>

<style scoped>
.parent {
  position: relative;
  max-height: 200px;
}
.loading-center {
  position: absolute;
  left: 50%;
  top: 40%;
}
</style>
